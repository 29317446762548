// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    BASE_URL: 'https://dev.api.india.dashboard.wginb.com/',
    LOGIN_SSO_URL: 'https://dev.api.india.dashboard.wginb.com/wgindiadashboardlogin/ssologin',
    MSAL_CONFIG: {
        clientId:"f529d809-fdd8-4726-9659-2fc6c6f8f51d",
        authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
        redirectUri: 'https://dev.india.dashboard.wginb.com',
        postLogoutRedirectUri: 'https://dev.india.dashboard.wginb.com'
    },
    BASE_API: {
        STATIC: 'wgindiadashboard/static',
        LIST_PILLAR: 'wgindiadashboard/collect/pillar/list',
        LIST_TEAM: 'wgindiadashboard/collect/team/list',
        PILLAR_API: 'wgindiadashboard/collect/pillar',
        TEAM_API: 'wgindiadashboard/collect/team',
        DATA_API: 'wgindiadashboard/collect/data',
        QUESTION_API: 'wgindiadashboard/collect/question',
        DATA_QUESTION_API: 'wgindiadashboard/view',
        DATA_VALUE_API: 'wgindiadashboard/collect/data/value',
        QUESTION_VALUE_API: 'wgindiadashboard/collect/question/value'
    }
};

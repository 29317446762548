<p-dialog 
    header="Delete {{ data }}" 
    [modal]="true"
    [style]="{ width: '40rem' }"
    [focusOnShow]="false"
    [draggable]="false"
    [(visible)]="makeVisible"
    (onHide)="closeDialog(null)">
    <p class="message">Are you sure you want to permanently delete this item ?</p>
    <ng-template pTemplate="footer">
        <button 
            type="submit" 
            class="custom-submit-button" 
            label="Yes"
            pButton
            (click)="closeDialog(true)">
        </button>
        <button 
            type="button" 
            class="p-button-outlined p-button-secondary" 
            label="No"
            pButton
            (click)="closeDialog(false)">
        </button>
    </ng-template>
</p-dialog>
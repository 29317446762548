import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({ providedIn: 'root' })
export class UtilityService {

    constructor(private messageService: MessageService) {}

    getCurrentQuarter(): string {

        let currentDate = new Date();
        let currentQuarter = Math.ceil((currentDate.getMonth() + 1) / 3) + "Q" + currentDate.getFullYear();
        return currentQuarter;
    }

    showSuccessMessage(message: string) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
    }

    showErrorMessage(message: string) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
    }
}
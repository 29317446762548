import { NgModule } from "@angular/core";

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TabViewModule } from 'primeng/tabview';
import { LoaderComponent } from "../loader/loader.component";

@NgModule({
    declarations: [
        LoaderComponent
    ],
    imports: [
        AccordionModule,
        ButtonModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule
    ],
    exports: [
        AccordionModule,
        ButtonModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule,
        LoaderComponent
    ]
})
export class SharedModule { }

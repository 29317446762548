import { NgModule } from "@angular/core";

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';

import { LoaderComponent } from "../loader/loader.component";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";

@NgModule({
    declarations: [
        LoaderComponent,
        DeleteDialogComponent
    ],
    imports: [
        AccordionModule,
        ButtonModule,
        DialogModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule,
        ToastModule,
        DialogModule,
        InputTextModule,
        RadioButtonModule,
        DropdownModule,
        TooltipModule
    ],
    exports: [
        AccordionModule,
        ButtonModule,
        DialogModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule,
        DialogModule,
        InputTextModule,
        RadioButtonModule,
        DropdownModule,
        LoaderComponent,
        DeleteDialogComponent,
        TooltipModule
    ]
})
export class SharedModule { }

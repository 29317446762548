import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from './shared/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'wg-india-dashboard-ui';
  currentUserName!: string;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.currentUserName$.subscribe({
      next: (username: string) => {
        if (username)
          this.currentUserName = username;
        else
          this.currentUserName = sessionStorage.getItem('current-username')!;
      }
    })
  }  

}

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent {

    @Input() makeVisible!: boolean;
    @Input() data!: string;
    @Output() closeDialogEvent = new EventEmitter<any>();

    closeDialog(data: any): void {
        this.closeDialogEvent.emit(data);
    }
}

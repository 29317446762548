import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 ) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                this.router.navigate(['/login']);
            
                const error = err.error['message'];
                return throwError(error);

            } else if (err.status === 403) {
                // auto logout if 403 response returned from api
                this.authenticationService.logout();
                this.router.navigate(['/login']);

                const error = "Token expired. Please try to login again.";
                return throwError(error);

            } else {
              const error = err.error.message || err.statusText;
              return throwError(error);
            }

        }));
    }
}

<app-loader *ngIf="loginLoader === 'true'? true: false"></app-loader>
<div> 
  <div class="outer-container">
    <div class="login-img">
      <img src="assets/wg-dashboard-login-img.png" alt="Login Image">
    </div>
 
    <div class="form-container">
      <div class="logo-img">
        <img src="assets/wg_logo_white_bg.png" alt="Dashboard UI Logo">
      </div>
      <button pButton
        id="login-btn"
        type="submit"
        label="LOGIN USING SSO"
        (click)="onLogin()">
      </button>
    </div>
  </div>
</div>

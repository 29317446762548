import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";

import { DataRecord } from "../../models/data";

@Component({
    selector: 'app-select-color',
    templateUrl: './select-color.component.html',
    styleUrls: ['./select-color.component.scss']
})
export class SelectColorComponent implements OnChanges {

    colors: DataRecord = {
        "Red": "#f5b5b5c2" ,
        "Green": "#9cc5a173",
        "Yellow": "#fad64366",
        "Blank": "#fffffff"
    };
    @Input() selectedColor: string = '';
    @Output() colorChangeEvent = new EventEmitter<string>();

    ngOnChanges(): void {
        this.selectedColor = Object.entries(this.colors).find(([_, v]) => v === this.selectedColor)?.[0] || '';
    }

    onColorChange(color: string): void {
        this.colorChangeEvent.emit(this.colors[color]);
    }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let isUserLoggedIn = this.authenticationService.currentUserName$.value;
    let idToken = sessionStorage.getItem('id-token');

    if (isUserLoggedIn && idToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${idToken}`,
        },
      });
    }
    const localDev = window.location.href.indexOf('.com') === -1; //location.host
    const serverlessApi = this.isServerlessApi(request.url);

    if (localDev && serverlessApi) {
      request = this.getAroundCorsPolicy(request);

    }
    return next.handle(request);
  }

  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }
    if (/api./.test(url)) {

      return true;
    }
    return false;
  }

  private getAroundCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/api./.test(req.url)) {
      return req.clone({
        url: req.url.replace(environment.BASE_URL, ''),
      });
    }
    return req;
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';

import { LoaderComponent } from "../loader/loader.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { SelectColorComponent } from "./components/select-color/select-color.component";

@NgModule({
    declarations: [
        LoaderComponent,
        ConfirmDialogComponent,
        SelectColorComponent
    ],
    imports: [
        CommonModule,
        AccordionModule,
        ButtonModule,
        DialogModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule,
        ToastModule,
        DialogModule,
        InputTextModule,
        FormsModule,
        RadioButtonModule,
        DropdownModule,
        TooltipModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        AccordionModule,
        ButtonModule,
        DialogModule,
        DropdownModule,
        ProgressSpinnerModule,
        TabViewModule,
        DialogModule,
        InputTextModule,
        RadioButtonModule,
        DropdownModule,
        LoaderComponent,
        ConfirmDialogComponent,
        TooltipModule,
        SelectColorComponent
    ]
})
export class SharedModule { }

import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '../shared/services/authentication.service';

@Component({ 
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loading = false;
  submitted = false;
  loginLoader!: string | null;
  private readonly _destroying$ = new Subject<null>();
  loginStatus = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserName$.value) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {

    if(!sessionStorage.getItem('isAuthLoading')){
      sessionStorage.setItem('isAuthLoading', "false");
    }
    this.loginLoader = sessionStorage.getItem("isAuthLoading");

    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (!result) {
          this.setSessionStorageAndLoader("false");
        }
      },
      error: () => {
        this.setSessionStorageAndLoader("false");
      }
    });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.router.navigate(['/login']);
      } else {
        this.setLoginDisplay();
      }
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  onLogin() {
    this.setSessionStorageAndLoader("true");
    this.authenticationService.loginSso();
  }

  setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);
      this.setMsalToken();
    } else {
      this.router.navigate(['/login']);
    }
  }

  setMsalToken() {
    const tokenRequest = {
        scopes: ["user.read"],
        account: this.msalService.instance.getAllAccounts()[0]
    }
    this.msalService.acquireTokenSilent(tokenRequest).subscribe((tokenResponse) => {
      if (tokenRequest != null) {
      sessionStorage.setItem("msal-access-token", tokenResponse.accessToken);
      sessionStorage.setItem("msal-id-token", tokenResponse.idToken);
      
      this.authenticationService.login({
          accessToken: sessionStorage.getItem('msal-access-token')!,
          idToken: sessionStorage.getItem('msal-id-token')!,
          email: this.msalService.instance.getActiveAccount()?.username!
        }).subscribe({
          next: () => {
            this.setSessionStorageAndLoader("false");
            this.router.navigate(['/home']);
          },
          error: () => {
            this.setSessionStorageAndLoader("false");
          }
        });
      }
    })
  }

  private setSessionStorageAndLoader(value: string) {
    this.loginLoader = value;
    sessionStorage.setItem('isAuthLoading', value);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}

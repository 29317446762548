<label class="col-form-label form-control-label required-field">
    Select Color
</label>
<div class="radio-btn-section">
    <div class="color" *ngFor="let color of colors | keyvalue">
        <p-radioButton 
        name="{{ color.key }}" 
        value="{{color.key }}" 
        [(ngModel)]="selectedColor"
        (ngModelChange)="onColorChange($event)" 
        [inputId]="color.key"/>
        <label [for]="color.key">{{ color.key }}</label>
        <div class="color-viewer" [ngStyle]="{ 'background': color.value }"></div>
    </div>
</div>

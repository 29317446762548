import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, concatMap, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoginRequest } from '../models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  currentUserName$!: BehaviorSubject<string>;

  private loginSsoUrl = `${environment.LOGIN_SSO_URL}`;
  private staticDataUrl  = `${environment.BASE_URL}${environment.BASE_API.STATIC}`;

  constructor(
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
    private msalService: MsalService
  ) { 
    this.currentUserName$ = new BehaviorSubject<string>(sessionStorage.getItem('current-username')!!);
  }

  loginSso() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  login(loginData: LoginRequest) {
    return this.http
    .post(this.loginSsoUrl, loginData)
    .pipe(
      concatMap((response: any) => {
        this.currentUserName$.next(response.user["USERNAME"]);
        sessionStorage.setItem('current-username', response.user["USERNAME"]);
        sessionStorage.setItem('id-token', response.idtoken);
        return this.http.get(this.staticDataUrl);
      }),
      tap((staticDataResp: any) => {
        sessionStorage.setItem("QUARTERS", JSON.stringify(staticDataResp.body.QUARTERS));
        sessionStorage.setItem("SCREEN", JSON.stringify(staticDataResp.body.VIEW_IDS));
      })
    );
  }
  
  logout() {
    sessionStorage.clear();
    this.currentUserName$.next('');
  }
}

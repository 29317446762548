<header 
  class="dashboard-ui-header" 
  *ngIf="userName"
>
  <!-- nav -->
  <nav class="navbar navbar-expand dashboard-ui-navbar">
    <div class="navbar-nav dashboard-ui-navbar-nav">
      <a routerLink="/" style="color: #fff" class="navbar-brand custom-logo">
          <img src="assets/wg_logo_black_bg.png" 
            class="d-inline-block align-top" 
            alt="Dashboard UI Logo"/>
      </a>
      <h4>WG India Dashboard</h4>
      <div class="right dashboard-ui-nav-menu" style="display: flex">
          <a class="nav-item nav-link no-event">
            <i style="margin-right: 5px" class="bi bi-person-circle"></i>
            {{ userName }}
          </a>
          <a class="nav-item nav-link" (click)="logout()">
            <i class="pi pi-sign-out"></i>
          </a>
      </div>
    </div>
  </nav>
   
  <nav class="navbar navbar-expand dashboard-ui-subnavbar pt-0">
    <div class="navbar-nav dashboard-ui-navbar-nav">
      <div class="dashboard-ui-nav-menu">
        <a routerLink="/home" 
          routerLinkActive="nav-link-active" 
          [routerLinkActiveOptions]="{exact: true}"
          class="nav-item nav-link" 
        >
            <i class="pi pi-home"></i>
            Home
        </a>
        <a routerLink="/data" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
        >
            <i class="pi pi-database"></i>
            Data
        </a>
        <a routerLink="/view" 
          routerLinkActive="nav-link-active"
          class="nav-item nav-link" 
        >
            <i class="pi pi-chart-bar"></i>
            View
        </a>
      </div>
    </div>
  </nav>
</header>

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    @Input() makeVisible!: boolean;
    @Input() header!: string;
    @Input() message!: string;
    @Output() closeDialogEvent = new EventEmitter<any>();

    closeDialog(data: any): void {
        this.closeDialogEvent.emit(data);
    }
}
